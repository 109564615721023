import React from "react";
import {
  BiLogoFacebookCircle,
  BiLogoInstagram,
  BiLogoLinkedinSquare,
  BiLogoYoutube,
} from "react-icons/bi";
import "./index.css"; // Ensure this import is present if it's not already

type ImageProps = {
  url?: string;
  src: string;
  alt?: string;
};

type Links = {
  title: string;
  url: string;
};

type SocialMediaLinks = {
  url: string;
  icon: React.ReactNode;
};

type FooterLink = {
  title: string;
  url: string;
};

type Props = {
  logo: ImageProps;
  quickLinks: Links[];
  socialMediaLinks: SocialMediaLinks[];
  footerText: string;
  footerLinks: FooterLink[];
};

export type FooterProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const Footer = (props: FooterProps) => {
  const { logo, footerText, quickLinks, footerLinks, socialMediaLinks } = {
    ...FooterDefaults,
    ...props,
  } as Props;

  return (
    <footer className="bg-[#E5F7F1] text-gray-800 py-8 md:py-10">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between mb-6">
          {/* Logo Section */}
          <a href={logo.url} className="mb-4 md:mb-0">
            <div className="logo-foot w-32 h-8 bg-cover"></div>
          </a>

          {/* Quick Links */}
          <ul className="flex flex-wrap justify-center space-x-4 mb-4 md:mb-0 text-sm font-semibold">
            {quickLinks.map((link, index) => (
              <li key={index}>
                <a
                  href={link.url}
                  className="hover:text-[#5BB7AF] transition-colors ml-5"
                >
                  {link.title}
                </a>
              </li>
            ))}
          </ul>

          {/* Social Media Links */}
          <div className="flex space-x-4">
            {socialMediaLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                className="text-xl hover:text-[#5BB7AF] ml-5 transition-colors"
              >
                {link.icon}
              </a>
            ))}
          </div>
        </div>

        <div className="h-px w-full bg-black mb-4" />

        <div className="flex flex-col md:flex-row items-center justify-between text-sm">
          <p className="text-center md:text-left mb-4 md:mb-0">{footerText}</p>
          <ul className="flex flex-wrap justify-center space-x-4 text-sm">
            {footerLinks.map((link, index) => (
              <li key={index}>
                <a
                  href={link.url}
                  className="underline hover:text-[#5BB7AF] ml-5 transition-colors"
                >
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export const FooterDefaults: FooterProps = {
  logo: {
    url: "#",
    src: "", // Add your logo source here
    alt: "Logo image",
  },
  quickLinks: [
    { title: "Home", url: "/#hero" },
    { title: "Services", url: "/#services" },
    { title: "About Us", url: "/#about" },
    { title: "Research", url: "/research" },
    { title: "Pricing", url: "/#pricing" },
    { title: "Contact", url: "https://register.my-e-health.com/contact" },
  ],
  socialMediaLinks: [
    {
      url: "https://www.facebook.com/myehealthofficial",
      icon: <BiLogoFacebookCircle />,
    },
    {
      url: "https://www.instagram.com/myehealthofficial/",
      icon: <BiLogoInstagram />,
    },
    {
      url: "https://www.linkedin.com/company/myehealthofficial/",
      icon: <BiLogoLinkedinSquare />,
    },
    {
      url: "https://www.youtube.com/@myehealthofficial",
      icon: <BiLogoYoutube />,
    },
  ],
  footerText: "© 2024 JAC International All rights reserved.",
  footerLinks: [
    { title: "Privacy Policy", url: "/privacy" },
    { title: "Terms of Service", url: "/t&c" },
    { title: "Cookies Settings", url: "/cookie-policy" },
  ],
};

Footer.displayName = "Footer";
