import React, { useState } from 'react';
import './Banner.css'; // Import CSS for styling

const Banner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const handleDismiss = () => {
    setIsBannerVisible(false);
  };

  if (!isBannerVisible) {
    return null; // Do not display the banner if dismissed
  }

  return (
    <div className="banner">
      <p>
        We use cookies to enhance your experience and provide personalized content.
        For the best performance on iPhones, please enable cross-site tracking in Safari.
      </p>
      <div className="steps">
        <p>1. Open the <strong>Settings</strong> app on your iPhone.</p>
        <p>2. Scroll down and select <strong>Safari</strong>.</p>
        <p>3. Under the <strong>Privacy & Security</strong> section, disable <strong>Prevent Cross-Site Tracking</strong>.</p>
      </div>
      <div className="banner-buttons">
        <button onClick={handleDismiss} className="banner-button got-it">Got it</button>
        <button 
  className="banner-button learn-more" 
  onClick={() => window.location.href = 'https://www.my-e-health.com/privacy'}
>
  Learn More
</button>

      </div>
    </div>
  );
};

export default Banner;
