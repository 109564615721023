import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

declare global {
  interface Window {
    googleTranslateElementInit: () => void;
    google: any; // You can provide more specific types if available
  }
}

const LanguageDropdown: React.FC = () => {
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {
    // Load the saved language from localStorage or default to English
    const savedLanguage = localStorage.getItem("selectedLanguage") || "en";
    setSelectedLanguage(savedLanguage);

    const initializeGoogleTranslate = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          includedLanguages: "en,sv",
          autoDisplay: false,
        },
        "google_translate_element"
      );

      // Function to set the language in the Google Translate dropdown
      const setLanguage = (language: string) => {
        const select = document.querySelector(".goog-te-combo") as HTMLSelectElement;
        if (select) {
          select.value = language;
          select.dispatchEvent(new Event("change"));
        }
      };

      // Observe the dropdown for initialization and set the language once ready
      const observer = new MutationObserver(() => {
        const select = document.querySelector(".goog-te-combo") as HTMLSelectElement;
        if (select) {
          observer.disconnect(); // Stop observing once the dropdown is ready
          // Set language based on the URL path or saved language
          if (location.pathname.endsWith("/sv")) {
            setLanguage("sv");
            setSelectedLanguage("sv");
            localStorage.setItem("selectedLanguage", "sv");
          } else {
            setLanguage(savedLanguage); // Apply the saved language from localStorage
          }
        }
      });
      observer.observe(document.body, { childList: true, subtree: true });
    };

    // Check if the Google Translate script is already loaded
    if (!window.google) {
      window.googleTranslateElementInit = initializeGoogleTranslate;

      const script = document.createElement("script");
      script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);
    } else {
      initializeGoogleTranslate();
    }
  }, [location.pathname]);

  useEffect(() => {
    // Update the selected language in localStorage whenever it changes
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <div className="language-dropdown fixed bottom-4 left-4 bg-white border border-gray-300 rounded-md shadow-md p-2">
      
      <div
        id="google_translate_element"
        className="inline-block text-center"
      ></div>
    </div>
  );
};

export default LanguageDropdown;
