import React, { useState, useEffect } from 'react';
import { FaCertificate, FaGlobe, FaShieldAlt } from 'react-icons/fa';

const Hero = () => {
  const handleSignUp = () => {
    window.location.href =
      'http://ded100755.my-e-health.com:8088/tracker?st=WEBPAGE&si=HomePage&it=CLICK&tl=register.my-e-health.com/start-registration-B2C&ci=HomePage';
  };

  const backgroundClasses = ['hero-bg', 'hero-bg1', 'hero-bg2'];

  const slides = [
    {
      title: 'Live Your Dream Life',
      subtitle: 'Our Service Empowers you to Take Control of your Mental Health without Stigma, Frustration, or Delays.',
    },
    {
      title: 'Embrace Wellness',
      subtitle: 'Your Journey to Better Mental Health Starts Here.',
    },
    {
      title: 'Empowering You',
      subtitle: 'Take the First Step towards a Healthier, Happier You.',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === backgroundClasses.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const formatSubtitle = (text) => {
    const phrasesToHighlight = [
      'Mental Health',
      'without Stigma, Frustration, or Delays',
      'Embrace your wellness and thrive',
      'Healthier, Happier You',
    ];

    let formattedText = text;

    phrasesToHighlight.forEach((phrase) => {
      const regex = new RegExp(`(${phrase})`, 'g');
      formattedText = formattedText.replace(
        regex,
        `<span class="font-semibold text-yellow-300">$1</span>`
      );
    });

    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  return (
    <div
      className={`relative flex w-full h-screen bg-cover bg-no-repeat bg-center ${backgroundClasses[currentIndex]}`}
    >
      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-teal-400/90 to-white/40" />

      <div className="absolute left-0 w-full md:w-1/2 h-full flex flex-col justify-center items-start p-6 md:p-14">
        <div className="flex flex-col gap-6 items-center md:items-start text-center md:text-left">
          {/* Slide Title with Larger Font on Mobile */}
          <h1 className="text-4xl sm:text-5xl md:text-4xl lg:text-5xl font-bold text-white">
            {slides[currentIndex].title}
          </h1>

          {/* Slide Subtitle with Highlighted Phrases and Larger Font on Mobile */}
          <p className="text-white text-xl sm:text-2xl md:text-2xl lg:text-3xl font-light leading-relaxed">
            {formatSubtitle(slides[currentIndex].subtitle)}
          </p>
        </div>

        {/* Centered Sign Up Button on Mobile */}
        <div className="mt-8 md:mt-8 flex justify-center md:justify-start w-full">
          <button
            className="p-3 px-6 md:p-3 md:px-6 bg-yellow-400 text-black text-base md:text-base lg:text-lg font-semibold rounded-full shadow-lg hover:bg-yellow-500 transition-colors duration-300"
            onClick={handleSignUp}
            aria-label="Sign Up"
          >
            Sign Up
          </button>
        </div>
      </div>

      {/* Wave SVG */}
      <svg
        className="absolute bottom-0 left-0 w-full h-12 md:h-16"
        viewBox="0 0 1440 100"
        preserveAspectRatio="none"
      >
        <path
          fill="white"
          d="M0,0 C480,100 960,100 1440,0 L1440,100 L0,100 Z"
        />
      </svg>
    </div>
  );
};

export default Hero;
